import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import SearchOutlined from '@mui/icons-material/SearchOutlined';

import NavLink from '../nav_link/NavLink';

const Foods = ["Strawberry", "Corn", "Burger", "Satay", "Meat", "Carrot", "Toast", "Cabbage", "Salad"]

const GreedyGame = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [userAccountStatus, setUserAccountStatus] = useState("agent");
     const [searchQuery, setSearchQuery] = useState(null);
     const tableHead = ["Game ID", "Total Winning Amount", "Result", "Date & Time"];


     const onPageChange = async (e, pageIndex, status) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * pageIndex);
               params.append("limit", 20);
               params.append("filterByNew", true);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               if(status){
                    params.append("accountStatus", status);
               }
               else params.append("accountStatus", userAccountStatus);
               const res = await axiosInstance.get('admin/greedy-game', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 0);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Greedy Game</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                         <div className='d-flex align-items-center' style={{border: '1px solid #A29694', marginRight: "20px", padding: '7px 20px', borderRadius: "5px"}}>
                              <input type="text" style={{background: "#F2EDF3", border: 'none', marginRight: "20px", }} placeholder='Search by game id...' onChange={(e)=>setSearchQuery(e.target.value.trim())}/>
                              <SearchOutlined htmlColor='#A29694' onClick={()=>{
                                   onPageChange(null, 0, userAccountStatus);
                              }} />
                         </div>
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage&&data.length != 0 ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id, gameId, result, totalWinningAmount, date } = props.data;


     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     return(
          <tr>
               <td>
                    <NavLink to={`/admin-greedy-dashboard?id=${gameId}`}  className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {gameId}
                    </NavLink>
               </td>
               <td>{totalWinningAmount|"--"}</td>
               <td>{result=='-1'?"--":Foods[result]}</td>
               <td><span>{new Date(date).toLocaleString()}</span></td>
            </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default GreedyGame;