import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';
import {io}  from "socket.io-client";

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import SearchOutlined from '@mui/icons-material/SearchOutlined';

import NavLink from '../nav_link/NavLink';
import { useLocation } from 'react-router-dom';

const Foods = ["Strawberry", "Corn", "Burger", "Satay", "Meat", "Carrot", "Toast", "Cabbage", "Salad"];
const winx = [2, 3, 4, 5, 7, 2, 2, 2, 10];

const BetGreedyGame = () => {

    const location = useLocation();

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const id = new URLSearchParams(location.search).get("id");
     const userId = new URLSearchParams(location.search).get("userId");

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [fourceWin, setFourceWin] = useState(null);
     const [dashboardData, setDashboardData] = useState({
        totalWinningAmount: 0,
        totalBettingAmount: 0,
        result: 0,
        date: new Date(),
        betting: [],
     });
     const tableHead = ["User Id", "Game Id", "Food", "Amount", "Winning Amount"];

     const socket = io("/game/greedy",{
        auth: {accessKey: "788fadfapifa4$#%sa&^fjainpvadu987070f7c078fhdsiuaf"}
     });


     const onPageChange = async (e, pageIndex, status) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * pageIndex);
               params.append("limit", 20);
               params.append("gameId", id);
               params.append("userId", userId);

               const res = await axiosInstance.get('admin/bet-greedy-game', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     const getDashboardData = async ()=> {
        try {
            const params = new URLSearchParams();
            params.append("gameId", id);

            const res = await axiosInstance.get('admin/dashboard-greedy-game', {
                 params: params
            });
            setDashboardData(res.data);
       } 
       catch (error) {
        
       }
     }

     const onFourceWinChange = food => {
        socket.emit("set_fource_winner", id, food, (success)=>{
            if(success){
                Toast.success("Done.");
            }
            else Toast.error("Failed to set winner")
        });
     }

     useEffect(async ()=>{
          onPageChange(null, 0);
          getDashboardData();

          // client-side
          socket.on("connect", () => {
               socket.emit("get_fource_winner", id, (fourceWin)=>{
                    setFourceWin(fourceWin);
               });
               socket.on(`on_update_betting_${id}`, (betting)=>{
                    setDashboardData({
                         ...dashboardData,
                         betting,
                    })
               });
          });
     }, []);

     return (
          <>

               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Greedy Game (Betting: {id})</h5>
                    </div>
               </div>
               <div className="container" style={{background: 'white', borderRadius: '5px', marginBottom: '20px', padding: '10px 25px'}}>
                    <div className='row'>
                         { dashboardData.betting ? dashboardData.betting.map((val)=> 
                            <BetItem className='col-2' label={Foods[val.food]} value={val.amount} />)
                        : null}
                    </div>
                    <div className="row mt-4">
                         <DataItem className='col-2' label="Total Winning Amount" value={dashboardData.totalWinningAmount} />
                         <DataItem className='col-2' label="Total Betting Amount" value={dashboardData.totalBettingAmount} />
                         <DataItem className='col-2' label="Result" value={dashboardData.result==-1?"--":Foods[dashboardData.result]} />
                         {fourceWin == -9 ? null : <div className="col-3">
                            <p>Fource Win</p>
                            <DropdownMenu defaultValue={Foods[fourceWin]} style={{marginBottom: '-40px'}} items={["Non", ...Foods]} onChange={(value, i)=> {
                                       onFourceWinChange(i-1);
                                }}/>
                         </div>}
                         <DataItem className='col-3' label="Date & Time" value={new Date(dashboardData.date).toLocaleString()} />
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item dashboardData={dashboardData} data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage&&data.length != 0 ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { userId, gameId, food, amount } = props.data;

     return(
          <tr>
               <td>
                    <NavLink to={`/admin-user-profile?id=${userId}`}  className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {userId}
                    </NavLink>
               </td>
               <td>{gameId}</td>
               <td>{food=='-1'?"--":Foods[food]}</td>
               <td>{amount}</td>
               <td>{props.dashboardData.result==food ? (food=='-1'?"--":amount*winx[food]) : "--"}</td>
            </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

const DataItem = (props) => {
    return (
         <div className={"" + props.className}>
              <div className="d-flex justify-content-between flex-column text-black">
                   <p style={{ fontSize: '14px', }}>{props.label}</p>
                   <div className="d-flex justify-content-between">
                        <p style={{fontWeight: 'bold', fontSize: '16px'}}>{props.value}</p>
                   </div>
              </div>
         </div>
    );
}

const BetItem = (props) => {
    return (
         <div className={"Deshboard_Item mt-3 " + props.className} style={{width: "12.499999995%"}}>
              <div className="Deshboard_Item_inner d-flex justify-content-between flex-column text-white" style={{}}>
                   <p style={{ fontSize: '14px',  }}>{props.label}</p>
                   <div className="d-flex justify-content-between ">
                        <p style={{fontWeight: 'bold', fontSize: '16px'}}>{props.value}</p>
                   </div>
              </div>
         </div>
    );
}

export default BetGreedyGame;