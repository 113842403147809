import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import { TextField } from '@mui/material';
import NavLink from '../nav_link/NavLink';

const UserFullPage = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const location = useLocation();

     const user_id = new URLSearchParams(location.search).get("id");
     const [user_name, setUserName] = useState(new URLSearchParams(location.search).get("userName"));

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          userName: "",
          userId: "",
          profilePhoto: "",
          loginId: "",
          referralId: "",
          gender: "",
          country: "",
          userLevel: "",
          accountStatus: "",
          lastLiveAt: "",
          lastOnlineAt: "",
          joinAt: "",
          myDiamonds: "",
          giftedDiamonds: "",
          balance: "",
          followerCount: "",
          followingCount: "",
          agentMemberCount: "",
          agentStatus: "",
          hostStatus: "",
          vipMembershipExpireAt: "",
     });
     const [errorMessage, setErrorMessage] = useState("Loading");

     function numberKMBT (labelValue) {

          // Nine Zeroes for Billions
          return Math.abs(Number(labelValue)) >= 1.0e+9
      
          ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
          // Six Zeroes for Millions 
          : Math.abs(Number(labelValue)) >= 1.0e+6
      
          ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
          // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3
      
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
      
          : Math.abs(Number(labelValue));
      
      }

      function parseMsIntoReadableTime(milliseconds){
          //Get hours from milliseconds
          var hours = milliseconds / (1000*60*60);
          var absoluteHours = Math.floor(hours);
          var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
        
          //Get remainder from hours and convert to minutes
          var minutes = (hours - absoluteHours) * 60;
          var absoluteMinutes = Math.floor(minutes);
          var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
        
          //Get remainder from minutes and convert to seconds
          var seconds = (minutes - absoluteMinutes) * 60;
          var absoluteSeconds = Math.floor(seconds);
          var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
        
        
          return h + ':' + m + ':' + s;
        }

     const toFirstLetterUpperCase = (str) => {
          if(str && str.length >= 2){
               return str.substring(0, 1).toUpperCase() + str.substring(1);
          }
          return str
     }

     const onChange = e => {
          setData({
               ...data,
               [e.target.name]: e.target.value,
          });
     }

     const validate = () => {
          if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const save = async ()=> {
          if(isSending) return;
          // if(!validate()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.patch("admin/user-full-data", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );

               Toast.success('Saved');
               setIsSending(false)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to update');
          }
     }

     const addBalance = () => {
          const input = prompt("Add balance", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               balance: Number(data.balance) + Number(input)
          });
     }

     const removeBalance = () => {
          const input = prompt("Remove balance", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               balance: Number(data.balance) - Number(input)
          });
     }

     const addDiamond = () => {
          const input = prompt("Add Diamonds", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               myDiamonds: Number(data.myDiamonds) + Number(input)
          });
     }

     const removeDiamond = () => {
          const input = prompt("Remove Diamonds", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               myDiamonds: Number(data.myDiamonds) - Number(input)
          });
     }

     useEffect(async ()=>{
          try {
               const params = new URLSearchParams();
               params.append("id", user_id);

               const res = await axiosInstance.get('admin/user', {
                    params: params
               });
               setErrorMessage(null);
               setData({
                    ...res.data,
                    lastOnlineAt: new Date(res.data.lastOnlineAt).toDateString(),
                    joinAt: new Date(res.data.date).toDateString(),
                    lastLiveAt: new Date(res.data.lastLiveAt).toDateString(),
               });
               if(!user_name){
                    setUserName(res.data.userName);
               }
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }, []);

     if(errorMessage){
          return(
               <>
                    <Header/>
                    <div className="container d-flex justify-content-between">
                         <div style={{height: '80px'}} className='d-flex align-items-center'>
                              <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                              <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>User</h5>
                         </div>
                    </div>
                    <div className="container box d-flex justify-content-center align-items-center" style={{height: '90vh'}}>
                         <h1>{errorMessage}</h1>
                    </div>
               </>
          );
     }

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>User</h5>
                    </div>
               </div>
               <div className="container d-flex justify-content-between" >
                    <div className="box d-flex align-items-center flex-column" style={{width: '40%'}}>
                         <div >
                              <img src={data.profilePhoto||"logo192.png"} style={{width: '200px', height: '200px', marginTop: '30px', marginBottom: '20px'}}/>
                         </div>
                         <h3>{user_name}</h3>
                         <span>Last online at: {data.lastOnlineAt}</span>
                         <div className='d-flex justify-content-between w-100' style={{padding: '20px 50px'}}>
                              <div className='d-flex align-items-center  flex-column'>
                                   <span style={{fontSize: '18px', fontWeight:'bold'}}>{numberKMBT(data.followerCount)}</span>
                                   <span style={{fontSize: '14px'}}>Followers</span>
                              </div>
                              <div className='d-flex align-items-center  flex-column'>
                                   <span style={{fontSize: '18px', fontWeight:'bold'}}>{numberKMBT(data.followingCount)}</span>
                                   <span style={{fontSize: '14px'}}>Following</span>
                              </div>
                              <div className='d-flex align-items-center  flex-column'>
                                   <span style={{fontSize: '18px', fontWeight:'bold'}}>{numberKMBT(data.myDiamonds)}</span>
                                   <span style={{fontSize: '14px'}}>Diamonds</span>
                              </div>
                         </div>
                         <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(180, 180, 180)', margin: '0px 50px'}}/>
                         <div style={{width: '90%', marginTop: '10px'}}>
                              <button className='btnx' onClick={()=>naviagte(`/admin-user-history?defaultType=earning&userName=${data.userName}&id=${user_id}`)}>View earning history</button>
                              <button className='btnx' onClick={()=>naviagte(`/admin-user-history?defaultType=topup&userName=${data.userName}&id=${user_id}`)}>View topup history</button>
                              <button className='btnx' onClick={()=>naviagte(`/admin-user-history?defaultType=withdrawal&userName=${data.userName}&id=${user_id}`)}>View withdrawal history</button>
                              <button className='btnx' onClick={()=>naviagte(`/admin-user-history?defaultType=gift&userName=${data.userName}&id=${user_id}`)}>View gifting history</button>
                         </div>
                         </div>
                    <div className="box d-flex justify-content-around row" style={{width: '57%'}}>
                         <div className="d-flex align-items-center flex-column col-6" style={{width: '50%'}}>
                              <TextField name='userName'value={data.userName} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="User Name" type="url" />
                              <TextField disabled name='userId'value={data.userId} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="User id" type="url" />
                              <TextField name='loginId'value={data.loginId} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Login id" type="url" />
                              <TextField disabled name='referralId'value={data.referralId} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Referral id" type="url" />
                              <TextField name='country'value={data.country} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Country" type="url" />
                              <TextField disabled name='userLevel'value={data.userLevel} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="User level" type="url" />
                              <TextField disabled name='lastLiveAt'value={parseMsIntoReadableTime(data.lastLiveMs)} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="live" type="url" />
                              <TextField disabled name='joinAt'value={data.joinAt} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Join at" type="url" />
                         </div>
                         <div className="d-flex align-items-center flex-column col-6" style={{width: '50%'}}>
                              <div style={{width: '100%', marginLeft: "30px"}}>
                                   <TextField disabled name='myDiamonds'value={data.myDiamonds} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Diamonds" type="url" />
                                   <button onClick={addDiamond} className="add_action_btn" style={{position: "absolute", right: 130, top: 37}}>Add</button>
                                   <button onClick={removeDiamond} className="remove_action_btn" style={{position: "absolute", right: 50, top: 37}}>Remove</button>
                              </div>
                              <div style={{width: '100%', marginLeft: "30px"}}>
                                   <TextField disabled name='balance'value={data.balance} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Balance" type="url" />
                                   <button onClick={addBalance} className="add_action_btn" style={{position: "absolute", right: 130, top: 112}}>Add</button>
                                   <button onClick={removeBalance} className="remove_action_btn" style={{position: "absolute", right: 50, top: 112}}>Remove</button>
                              </div>

                              <DropdownMenu defaultValue={"Gender: " + toFirstLetterUpperCase(data.gender).replace("_", " ")} width="90%" className="dropdown_x w-100" items={data.gender == "male" ? ["Gender: Male", "Gender: Female"] : ["Gender: Female", "Gender: Male"]} onChange={(value, i)=> setData({ ...data, gender: data.gender == i==0?"male":"female" })}/>
                              <DropdownMenu defaultValue={"Account status: " + toFirstLetterUpperCase(data.accountStatus).replace("_", " ")} width="90%" className="dropdown_x w-100" items={["Account status: Active", "Account status: Blocked"]} onChange={(value, i)=> setData({ ...data, accountStatus: i==0?"active":"blocked" })}/>
                              <DropdownMenu defaultValue={"Agent status: " + toFirstLetterUpperCase(data.agentStatus).replace("_", " ")} width="90%" className="dropdown_x w-100" items={["Agent status: Enabled", "Agent status: Disabled"]} onChange={(value, i)=> setData({ ...data, agentStatus: i==0?"enabled":"desabled" })}/>
                              <DropdownMenu defaultValue={"Host status: " + toFirstLetterUpperCase(data.hostStatus).replace("_", " ")} width="90%" className="dropdown_x w-100" items={["Host status: Enabled", "Host status: Disabled"]} onChange={(value, i)=> setData({ ...data, hostStatus: i==0?"enabled":"desabled" })}/>
                              <DropdownMenu defaultValue={new Date(data.vipMembershipExpireAt).getTime() > Date.now() ? "VIP expire at: " + new Date(data.vipMembershipExpireAt).toDateString() : "VIP: None"} width="90%" className="dropdown_x w-100" items={["VIP: None", "VIP: 1 month", "VIP: 3 month", "VIP: 6 month", "VIP: 12 month"]} 
                                   onChange={(value, i)=> {
                                        var date;
                                        const month1 = (((1000*60)*60)*24) * 30;
                                        if(i==0){
                                             date = new Date(Date.now());
                                        }
                                        else if(i==1){
                                             date = new Date(Date.now() + month1);
                                        }
                                        else if(i==2){
                                             date = new Date(Date.now() + (month1 * 3));
                                        }
                                        else if(i==3){
                                             date = new Date(Date.now() + (month1 * 6));
                                        }
                                        else if(i==4){
                                             date = new Date(Date.now() + (month1 * 12));
                                        }
                                        else{
                                             date = data.vipMembershipExpireAt
                                        }
                                        setData({ ...data, 
                                             vipMembershipExpireAt: date
                                        })
                                   }}/>
                              <button className='btnx' style={{marginTop: '20px', width: '90%', cursor: isSending ? "progress" : 'pointer'}} onClick={save}>Save the changes</button>
                              
                         
                         </div>
                         
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id, userName, userId, profilePhoto, country, gender, agentStatus, hostStatus, vipEnabled } = props.data;

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     return(
          <tr>
               <td>
                    <img className='img_profile_photo' src={profilePhoto ? profilePhoto : "logo192.png"}/> 
                    <NavLink to="/" a className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {userName}
                    </NavLink>
               </td>
               <td>{userId}</td>
               <td>{country}</td>
               <td><span>{gender.charAt(0).toUpperCase() + gender.slice(1)}</span></td>
               <td>{getStatusView(agentStatus)}</td>
               <td>{getStatusView(hostStatus)}</td>
               <td><span className={"item_status_view " + (vipEnabled ? "status_c_green" : "status_c_red")}>{vipEnabled ? "Active" : "None"}</span></td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default UserFullPage;