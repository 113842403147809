import React, { useState, useEffect } from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton, Switch } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

import Header from './../header/Header';

const Setting = ()=>{

     const [isSending, setIsSending] = useState(false);
     const [isResetDialogOpen, setResetDialogOpen] = useState(false);
     const [data, setData] = useState({
          password: "",
          versionCode: 1,
          reviewUsLink: "https",
          facebookLink: "",
          faqLink: "",
          connectUsLink: "",
          aboutLink: "",
          privacyPolicyLink: "",
          termsAndConditionsLink: "",
          instagramLink: "",
          telegramLink: "",
          greedyEnabled: true,
     });

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     useEffect(async ()=>{
          console.log("called");
          try {
               const res = await axiosInstance.get('admin/setting');
               console.log(res.data);
               setData(res.data);
          } 
          catch (error) {
               Toast.error('Failed');
          }
     }, []);

     const onChange = (e)=>{
          setData({
               ...data,
               [e.target.name]: e.target.value,
          });
     }

     const validate = () => {
          if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{

          if(!validate()) return;

          setIsSending(true)
          try {
               
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.patch("admin/setting", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );
               setData(res.data);

               Toast.success('Saved');
               setIsSending(false)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to update');
          }
     }

     const resetHostEarning = async ()=> {
          try {
               const res = await axiosInstance.patch("admin/reset-host-earnings");
               Toast.success('Reset successfull');
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to reset');
          }
     }

     return(
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Setting</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '10px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         <Alert
                              style={{width: '90%',marginTop: '20px'}}
                              severity="success"
                              action={<Button variant="outlined" onClick={()=>{setResetDialogOpen(true);}}> Reset </Button> }>
                              Reset All Host's Earning
                         </Alert>
                         <div style={{width: '90%',marginTop: '20px'}} className='d-flex justify-content-between flex-row'>
                              <p>Greedy Game</p>
                              <Switch inputProps={{ 'aria-label': 'controlled' }} checked={data.greedyEnabled} onChange={(e)=>setData({...data, greedyEnabled: e.target.checked,})}/>
                         </div>
                         <TextField name='password' value={data.password} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Password" type="text" inputProps={{ maxLength: 20 }}/>
                         <TextField name='versionCode' value={data.versionCode} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Version code" type="number"/>
                         <TextField name='reviewUsLink' value={data.reviewUsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Review us link" type="text"/>
                         <TextField name='facebookLink' value={data.facebookLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Facebook link" type="text"/>
                         <TextField name='faqLink' value={data.faqLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="FAQ link" type="text"/>
                         <TextField name='connectUsLink' value={data.connectUsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Connect us link" type="text"/>
                         <TextField name='aboutLink' value={data.aboutLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="About us link" type="text"/>
                         <TextField name='privacyPolicyLink' value={data.privacyPolicyLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Privacy policy link" type="text"/>
                         <TextField name='termsAndConditionsLink' value={data.termsAndConditionsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Terms and conditions link" type="text"/>
                         <TextField name='instagramLink' value={data.instagramLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Instagram link" type="text"/>
                         <TextField name='telegramLink' value={data.telegramLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Telegram link" type="text"/>
                         
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom: "30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Save</button>
                    </div>
               </div>
               <Dialog
                    open={isResetDialogOpen}
                    onClose={()=>{setResetDialogOpen(false);}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                         
                         <DialogTitle id="alert-dialog-title">
                              {"Reset Earnings?"}
                         </DialogTitle>
                         <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                   Do you want to reset the host's earning?
                              </DialogContentText>
                         </DialogContent>
                         <DialogActions>
                              <Button onClick={()=>setResetDialogOpen(false)}>Not now</Button>
                              <Button onClick={()=>{setResetDialogOpen(false);resetHostEarning();}} autoFocus>Reset</Button>
                         </DialogActions>
               </Dialog>
          </>
     );
}

export default Setting;